/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./library-track-toggle-button.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i3 from "@angular/material/button";
import * as i4 from "@angular/cdk/a11y";
import * as i5 from "@angular/platform-browser/animations";
import * as i6 from "@angular/material/tooltip";
import * as i7 from "@angular/cdk/overlay";
import * as i8 from "@angular/cdk/scrolling";
import * as i9 from "@angular/cdk/platform";
import * as i10 from "@angular/cdk/bidi";
import * as i11 from "@angular/platform-browser";
import * as i12 from "../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i13 from "@angular/material/icon";
import * as i14 from "@angular/common";
import * as i15 from "./library-track-toggle-button.component";
import * as i16 from "../user-library.service";
var styles_LibraryTrackToggleButtonComponent = [i0.styles];
var RenderType_LibraryTrackToggleButtonComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LibraryTrackToggleButtonComponent, data: {} });
export { RenderType_LibraryTrackToggleButtonComponent as RenderType_LibraryTrackToggleButtonComponent };
function View_LibraryTrackToggleButtonComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 4, "button", [["mat-icon-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"], [null, "longpress"], [null, "keydown"], [null, "touchend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("longpress" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).show() !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2)._handleKeydown($event) !== false);
        ad = (pd_1 && ad);
    } if (("touchend" === en)) {
        var pd_2 = (i1.ɵnov(_v, 2)._handleTouchend() !== false);
        ad = (pd_2 && ad);
    } if (("click" === en)) {
        var pd_3 = (_co.library.add([_co.track]) !== false);
        ad = (pd_3 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(1, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.FocusMonitor, [2, i5.ANIMATION_MODULE_TYPE]], null, null), i1.ɵdid(2, 212992, null, 0, i6.MatTooltip, [i7.Overlay, i1.ElementRef, i8.ScrollDispatcher, i1.ViewContainerRef, i1.NgZone, i9.Platform, i4.AriaDescriber, i4.FocusMonitor, i6.MAT_TOOLTIP_SCROLL_STRATEGY, [2, i10.Directionality], [2, i6.MAT_TOOLTIP_DEFAULT_OPTIONS], [2, i11.HAMMER_LOADER]], { message: [0, "message"] }, null), (_l()(), i1.ɵeld(3, 0, null, 0, 1, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"], ["svgIcon", "favorite-border"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i12.View_MatIcon_0, i12.RenderType_MatIcon)), i1.ɵdid(4, 9158656, null, 0, i13.MatIcon, [i1.ElementRef, i13.MatIconRegistry, [8, null], [2, i13.MAT_ICON_LOCATION]], { svgIcon: [0, "svgIcon"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_2 = "Add to library"; _ck(_v, 2, 0, currVal_2); var currVal_5 = "favorite-border"; _ck(_v, 4, 0, currVal_5); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1).disabled || null); var currVal_1 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_3 = i1.ɵnov(_v, 4).inline; var currVal_4 = (((i1.ɵnov(_v, 4).color !== "primary") && (i1.ɵnov(_v, 4).color !== "accent")) && (i1.ɵnov(_v, 4).color !== "warn")); _ck(_v, 3, 0, currVal_3, currVal_4); }); }
function View_LibraryTrackToggleButtonComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 4, "button", [["class", "active"], ["mat-icon-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"], [null, "longpress"], [null, "keydown"], [null, "touchend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("longpress" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).show() !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2)._handleKeydown($event) !== false);
        ad = (pd_1 && ad);
    } if (("touchend" === en)) {
        var pd_2 = (i1.ɵnov(_v, 2)._handleTouchend() !== false);
        ad = (pd_2 && ad);
    } if (("click" === en)) {
        var pd_3 = (_co.library.remove([_co.track]) !== false);
        ad = (pd_3 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(1, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.FocusMonitor, [2, i5.ANIMATION_MODULE_TYPE]], null, null), i1.ɵdid(2, 212992, null, 0, i6.MatTooltip, [i7.Overlay, i1.ElementRef, i8.ScrollDispatcher, i1.ViewContainerRef, i1.NgZone, i9.Platform, i4.AriaDescriber, i4.FocusMonitor, i6.MAT_TOOLTIP_SCROLL_STRATEGY, [2, i10.Directionality], [2, i6.MAT_TOOLTIP_DEFAULT_OPTIONS], [2, i11.HAMMER_LOADER]], { message: [0, "message"] }, null), (_l()(), i1.ɵeld(3, 0, null, 0, 1, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"], ["svgIcon", "favorite"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i12.View_MatIcon_0, i12.RenderType_MatIcon)), i1.ɵdid(4, 9158656, null, 0, i13.MatIcon, [i1.ElementRef, i13.MatIconRegistry, [8, null], [2, i13.MAT_ICON_LOCATION]], { svgIcon: [0, "svgIcon"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_2 = "Remove from library"; _ck(_v, 2, 0, currVal_2); var currVal_5 = "favorite"; _ck(_v, 4, 0, currVal_5); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1).disabled || null); var currVal_1 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_3 = i1.ɵnov(_v, 4).inline; var currVal_4 = (((i1.ɵnov(_v, 4).color !== "primary") && (i1.ɵnov(_v, 4).color !== "accent")) && (i1.ɵnov(_v, 4).color !== "warn")); _ck(_v, 3, 0, currVal_3, currVal_4); }); }
function View_LibraryTrackToggleButtonComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_LibraryTrackToggleButtonComponent_3)), i1.ɵdid(1, 16384, null, 0, i14.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.library.has(_co.track); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_LibraryTrackToggleButtonComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_LibraryTrackToggleButtonComponent_1)), i1.ɵdid(1, 16384, null, 0, i14.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["removeTrack", 2]], null, 0, null, View_LibraryTrackToggleButtonComponent_2))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.library.has(_co.track); var currVal_1 = i1.ɵnov(_v, 2); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_LibraryTrackToggleButtonComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "library-track-toggle-button", [], null, null, null, View_LibraryTrackToggleButtonComponent_0, RenderType_LibraryTrackToggleButtonComponent)), i1.ɵdid(1, 49152, null, 0, i15.LibraryTrackToggleButtonComponent, [i16.UserLibrary], null, null)], null, null); }
var LibraryTrackToggleButtonComponentNgFactory = i1.ɵccf("library-track-toggle-button", i15.LibraryTrackToggleButtonComponent, View_LibraryTrackToggleButtonComponent_Host_0, { track: "track" }, {}, []);
export { LibraryTrackToggleButtonComponentNgFactory as LibraryTrackToggleButtonComponentNgFactory };
