import * as tslib_1 from "tslib";
import { AfterViewInit, ChangeDetectorRef, ElementRef, EventEmitter, NgZone, OnDestroy, OnInit, QueryList } from '@angular/core';
import { Track } from '../../../models/Track';
import { Player } from '../../player/player.service';
import { FormattedDuration } from '../../player/formatted-duration.service';
import { Album } from '../../../models/Album';
import { WpUtils } from '../../web-player-utils';
import { WebPlayerUrls } from '../../web-player-urls.service';
import { TrackContextMenuComponent } from '../track-context-menu/track-context-menu.component';
import { PlaylistTrackContextMenuComponent } from '../../playlists/playlist-track-context-menu/playlist-track-context-menu.component';
import { SelectedTracks } from './selected-tracks.service';
import { WebPlayerState } from '../../web-player-state.service';
import { BrowserEvents } from 'common/core/services/browser-events.service';
import { ContextMenu } from 'common/core/ui/context-menu/context-menu.service';
import { MatColumnDef, MatTable } from '@angular/material';
import { isDataSource } from '@angular/cdk/collections';
import { hasKey } from '@common/core/utils/has-key';
export class TrackListComponent {
    constructor(player, duration, urls, contextMenu, zone, el, selectedTracks, browserEvents, state, cd) {
        this.player = player;
        this.duration = duration;
        this.urls = urls;
        this.contextMenu = contextMenu;
        this.zone = zone;
        this.el = el;
        this.selectedTracks = selectedTracks;
        this.browserEvents = browserEvents;
        this.state = state;
        this.cd = cd;
        this.subscriptions = [];
        this.album = new Album();
        this.showArtist = false;
        this.showAlbum = false;
        this.showPopularity = false;
        this.showAddedAt = false;
        this.showHeader = true;
        this.contextMenuParams = { type: 'track', extra: {} };
        this.delete = new EventEmitter();
        this.orderChanged = new EventEmitter();
        this.reorderEnabled = false;
        this.columns = [];
        this.trackByFn = (i, track) => track.id;
    }
    ngOnInit() {
        this.bindHammerEvents();
        this.bindKeyboardShortcuts();
        this.reorderEnabled = !!this.orderChanged.observers.length && !this.state.isMobile;
        if (this.select) {
            this.selectedTracks.add(this.select);
        }
    }
    ngAfterViewInit() {
        this.columnDefs.forEach(columnDef => {
            this.columns.push(columnDef.name);
        });
        this.cd.detectChanges();
    }
    ngOnDestroy() {
        this.subscriptions.forEach(subscription => {
            subscription.unsubscribe();
        });
        this.subscriptions = [];
    }
    getTracks() {
        if (isDataSource(this.dataSource)) {
            return hasKey('data', this.dataSource) ?
                this.dataSource.data :
                this.dataSource.getData();
        }
        else {
            return this.dataSource;
        }
    }
    trackIsPlaying(track) {
        return this.player.isPlaying() && this.player.cued(track);
    }
    playTrack(track, index) {
        if (this.player.cued(track)) {
            this.player.play();
        }
        else {
            this.playFrom(index);
        }
    }
    toggleTrackPlayback(track, index) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (this.trackIsPlaying(track)) {
                this.player.pause();
            }
            else {
                this.playTrack(track, index);
            }
        });
    }
    getTrackPopularity(track) {
        if (track.local_only) {
            if (!track.local_popularity) {
                track.local_popularity = (track.plays_count / Math.max(...(this.getTracks().map(t => t.plays_count)))) * 50;
            }
            return track.local_popularity;
        }
        else {
            return track.spotify_popularity || 50;
        }
    }
    showContextMenu(track, e) {
        e.stopPropagation();
        e.preventDefault();
        this.contextMenu.open(this.getContextMenuComponent(), e.target, { data: this.getContextMenuParams(track) });
    }
    /**
     * Get params needed to open context menu for track.
     */
    getContextMenuParams(track) {
        return Object.assign({ item: track, type: this.contextMenuParams.type, selectedTracks: this.selectedTracks }, this.contextMenuParams.extra);
    }
    /**
     * Get context menu component based on specified type.
     */
    getContextMenuComponent() {
        if (this.contextMenuParams.type === 'playlistTrack') {
            return PlaylistTrackContextMenuComponent;
        }
        else {
            return TrackContextMenuComponent;
        }
    }
    /**
     * Add tracks from specified index to player queue and start playback.
     */
    playFrom(index) {
        let tracks = this.getTracks().slice(index, this.getTracks().length);
        tracks = WpUtils.assignAlbumToTracks(tracks, this.album);
        this.player.overrideQueue({ tracks, queuedItemId: this.queueItemId }).then(() => {
            this.player.play();
        });
    }
    formatTrackDuration(track) {
        return this.duration.fromMilliseconds(track.duration);
    }
    /**
     * Bind handlers to needed hammer.js events.
     */
    bindHammerEvents() {
        let hammer, singleTap, doubleTap;
        this.zone.runOutsideAngular(() => {
            hammer = new Hammer.Manager(this.el.nativeElement);
            singleTap = new Hammer.Tap({ event: 'singletap' });
            doubleTap = new Hammer.Tap({ event: 'doubletap', taps: 2 });
            hammer.add([doubleTap, singleTap]);
        });
        // select track on tap or multiple tracks when ctrl is pressed
        hammer.on('singletap', e => {
            this.zone.run(() => {
                const data = this.getTrackFromEvent(e);
                if (!data || !data.track)
                    return;
                if (this.state.isMobile && !e.target.closest('.track-options-button, a')) {
                    const i = this.getTracks().findIndex(t => t.id === data.track.id);
                    this.toggleTrackPlayback(data.track, i);
                }
                if (!e.srcEvent.ctrlKey) {
                    this.selectedTracks.clear();
                    this.selectedTracks.add(data.track);
                }
                else {
                    this.selectedTracks.toggle(data.track);
                }
            });
        });
        // play track on double tap
        hammer.on('doubletap', e => {
            this.zone.run(() => {
                const data = this.getTrackFromEvent(e);
                if (!data)
                    return;
                this.playTrack(data.track, data.index);
            });
        });
        // deselect all tracks when clicked outside of track list.
        const sub = this.browserEvents.globalClick$.subscribe(e => {
            if (!e.target.closest('.track-list-row')) {
                this.selectedTracks.clear();
            }
        });
        this.subscriptions.push(sub);
    }
    /**
     * Get track from specified hammer tap event.
     */
    getTrackFromEvent(e) {
        if (!e.target)
            return;
        const row = e.target.closest('.mat-row');
        if (!row)
            return;
        const id = +row.getAttribute('data-id');
        const i = this.getTracks().findIndex(track => track.id === id);
        return { track: this.getTracks()[i], index: i };
    }
    /**
     * Initiate tracks list shortcuts.
     */
    bindKeyboardShortcuts() {
        const sub = this.browserEvents.globalKeyDown$.subscribe((e) => {
            // ctrl+a - select all tracks
            if (e.ctrlKey && e.keyCode === this.browserEvents.keyCodes.letters.a) {
                this.getTracks().forEach(track => this.selectedTracks.add(track));
                e.preventDefault();
            }
            // delete - fire delete event
            else if (e.keyCode === this.browserEvents.keyCodes.delete && !this.selectedTracks.empty()) {
                this.delete.emit(this.selectedTracks.all());
                this.selectedTracks.clear();
                e.preventDefault();
            }
        });
        this.subscriptions.push(sub);
    }
}
