/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./genre-item.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "../../shared/media-image/media-image.component.ngfactory";
import * as i5 from "../../shared/media-image/media-image.component";
import * as i6 from "../../../../common/core/config/settings.service";
import * as i7 from "../../../../common/core/translations/translate.directive";
import * as i8 from "../../../../common/core/translations/translations.service";
import * as i9 from "./genre-item.component";
import * as i10 from "../../web-player-urls.service";
var styles_GenreItemComponent = [i0.styles];
var RenderType_GenreItemComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_GenreItemComponent, data: {} });
export { RenderType_GenreItemComponent as RenderType_GenreItemComponent };
export function View_GenreItemComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "img-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "media-image", [["class", "genre-image"], ["default", "artist"]], null, null, null, i4.View_MediaImageComponent_0, i4.RenderType_MediaImageComponent)), i1.ɵdid(4, 573440, null, 0, i5.MediaImageComponent, [i6.Settings], { media: [0, "media"], default: [1, "default"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "genre-name"], ["trans", ""]], null, null, null, null, null)), i1.ɵdid(6, 4341760, null, 0, i7.TranslateDirective, [i1.ElementRef, i8.Translations, i6.Settings], null, null), (_l()(), i1.ɵted(7, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.urls.genre(_co.genre); _ck(_v, 1, 0, currVal_2); var currVal_3 = _co.genre; var currVal_4 = "artist"; _ck(_v, 4, 0, currVal_3, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 1).target; var currVal_1 = i1.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_5 = _co.genre.name; _ck(_v, 7, 0, currVal_5); }); }
export function View_GenreItemComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "genre-item", [["class", "media-grid-item"]], null, null, null, View_GenreItemComponent_0, RenderType_GenreItemComponent)), i1.ɵdid(1, 49152, null, 0, i9.GenreItemComponent, [i10.WebPlayerUrls], null, null)], null, null); }
var GenreItemComponentNgFactory = i1.ɵccf("genre-item", i9.GenreItemComponent, View_GenreItemComponent_Host_0, { genre: "genre" }, {}, []);
export { GenreItemComponentNgFactory as GenreItemComponentNgFactory };
